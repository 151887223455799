import React, { useState, useEffect } from 'react';
import { Tabs, TabsContent, TabsList, TabsTrigger } from "./components/ui/tabs";
import { Input } from "./components/ui/input";
import { Button } from "./components/ui/button";
import { Card, CardHeader, CardTitle, CardContent } from "./components/ui/card";


const CombinedCalculatorApp = () => {
  const [activeTab, setActiveTab] = useState("focalStop");

  // FocalStopCalculator state and logic
  const focalStops = [
    1, 1.1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.5, 2.8, 3.2, 3.5, 4, 4.5, 5, 5.6, 6.3, 7.1, 8, 9, 10, 11, 13, 14, 16, 18, 20, 22
  ];
  const [focal1, setFocal1] = useState(1);
  const [focal2, setFocal2] = useState(1);
  const [focalResult, setFocalResult] = useState({ thirds: 0, wholeStops: 0, remainingThirds: 0 });

  useEffect(() => {
      const index1 = focalStops.indexOf(focal1);
      const index2 = focalStops.indexOf(focal2);
      const totalThirds = Math.abs(index2 - index1);
      const wholeStops = Math.floor(totalThirds / 3);
      const remainingThirds = totalThirds % 3;
      setFocalResult({ thirds: totalThirds, wholeStops, remainingThirds });
    }, [focal1, focal2, focalStops]);

  const formatFocalResult = () => {
    const { thirds, wholeStops, remainingThirds } = focalResult;
    if (thirds === 0) return "0 stops";
    
    let formattedResult = `${thirds} third${thirds !== 1 ? 's' : ''} of a stop`;
    
    if (wholeStops > 0 || remainingThirds > 0) {
      formattedResult += ` (${wholeStops} stop${wholeStops !== 1 ? 's' : ''}`;
      if (remainingThirds > 0) {
        formattedResult += ` and ${remainingThirds}/3`;
      }
      formattedResult += ")";
    }
    
    return formattedResult;
  };

  // ApertureCalculator state and logic
  const [aperture, setAperture] = useState('');
  const [stopDifference, setStopDifference] = useState('');
  const [results, setResults] = useState(null);
  const standardApertures = [
    1, 1.4, 2, 2.8, 4, 5.6, 8, 11, 16, 22, 32, 45, 64
  ];

  const findNearestStandardAperture = (value) => {
    return standardApertures.reduce((prev, curr) => 
      Math.abs(curr - value) < Math.abs(prev - value) ? curr : prev
    );
  };

  const calculateApertures = () => {
    const baseAperture = parseFloat(aperture);
    const stops = parseFloat(stopDifference);
    
    if (isNaN(baseAperture) || isNaN(stops)) {
      alert('Please enter valid numeric values.');
      return;
    }
    const widerExact = baseAperture / Math.pow(2, stops / 2);
    const narrowerExact = baseAperture * Math.pow(2, stops / 2);
    const wider = findNearestStandardAperture(widerExact);
    const narrower = findNearestStandardAperture(narrowerExact);
    setResults({
      wider: wider.toFixed(1),
      narrower: narrower.toFixed(1),
      widerExact: widerExact.toFixed(2),
      narrowerExact: narrowerExact.toFixed(2)
    });
  };

  return (
    <div className="container mx-auto p-4">
      <Tabs value={activeTab} onValueChange={setActiveTab}>
        <TabsList className="grid w-full grid-cols-2">
          <TabsTrigger value="focalStop">Focal Stop Calculator</TabsTrigger>
          <TabsTrigger value="aperture">Aperture Calculator</TabsTrigger>
        </TabsList>
        <TabsContent value="focalStop">
          <Card className="w-full max-w-md mx-auto mt-10">
            <CardHeader>
              <CardTitle>Focal Stop Interval Calculator</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="flex flex-col space-y-4 mb-4">
                <div>
                  <label className="block mb-2">Focal Stop 1</label>
                  <select
                    value={focal1}
                    onChange={(e) => setFocal1(parseFloat(e.target.value))}
                    className="w-full p-2 border rounded"
                  >
                    {focalStops.map((stop) => (
                      <option key={stop} value={stop}>
                        f/{stop}
                      </option>
                    ))}
                  </select>
                </div>
                <div>
                  <label className="block mb-2">Focal Stop 2</label>
                  <select
                    value={focal2}
                    onChange={(e) => setFocal2(parseFloat(e.target.value))}
                    className="w-full p-2 border rounded"
                  >
                    {focalStops.map((stop) => (
                      <option key={stop} value={stop}>
                        f/{stop}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="bg-gray-100 p-4 rounded">
                <p className="text-lg">
                  Interval: <strong>{formatFocalResult()}</strong>
                </p>
              </div>
            </CardContent>
          </Card>
        </TabsContent>
        <TabsContent value="aperture">
          <Card className="w-full max-w-md mx-auto mt-10">
            <CardHeader>
              <CardTitle>Precise F-stop Aperture Calculator</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="space-y-4">
                <div>
                  <label htmlFor="aperture" className="block text-sm font-medium text-gray-700">
                    Aperture (f/stop)
                  </label>
                  <Input
                    id="aperture"
                    type="number"
                    value={aperture}
                    onChange={(e) => setAperture(e.target.value)}
                    placeholder="e.g. 2.8"
                    className="mt-1"
                  />
                </div>
                <div>
                  <label htmlFor="stop-difference" className="block text-sm font-medium text-gray-700">
                    Stop Difference
                  </label>
                  <Input
                    id="stop-difference"
                    type="number"
                    value={stopDifference}
                    onChange={(e) => setStopDifference(e.target.value)}
                    placeholder="e.g. 3"
                    className="mt-1"
                  />
                </div>
                <Button onClick={calculateApertures} className="w-full">
                  Calculate
                </Button>
                {results && (
                  <div className="mt-4">
                    <h3 className="text-lg font-semibold">Results:</h3>
                    <p>Wider aperture: f/{results.wider} (Exact: f/{results.widerExact})</p>
                    <p>Narrower aperture: f/{results.narrower} (Exact: f/{results.narrowerExact})</p>
                  </div>
                )}
              </div>
            </CardContent>
          </Card>
        </TabsContent>
      </Tabs>
    </div>
  );
};

export default CombinedCalculatorApp;
